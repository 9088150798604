<template>
  <v-container fluid>
    <TableViewComponent
      title="Table établissements.DIRECTION"
      @addItemEvent="onAddEstablishment()"
      addItemLabel="ajouter un établissement"
      itemLabel="établissement"
      itemsLabel="établissements"
      :items="establishments"
      :columns="buildColumns()"
      deleteItemLabel="Voulez-vous supprimer cet établissement?"
      :loading="loading"
      :vcols="{ xl: '6', lg: '9', md: '10', sm: '12', xs: '12' }"
      :rolesForEdition="rolesForEdition"
      :rolesForAddDelete="rolesForAddDelete"
      :rolesForRead="rolesForRead"
    >
    </TableViewComponent>

    <!-- afficher des messages -->
    <v-snackbar
      v-model="snackbarVisible"
      :color="snackbarColor"
      :timeout="snackbarTimeout"
      :left="snackbarLeft"
      :right="snackbarRight"
      :top="snackbarTop"
      :bottom="snackbarBottom"
      >{{ snackbarMessage }}</v-snackbar
    >
  </v-container>
</template>

<script>
import TableViewComponent from "@/components/ui/TableViewComponent.vue";

import SnackBarMixin from "@/components/mixins/SnackBarMixin.js";

import EstablishmentsService from "@/service/etablishment/etablishment_service.js";
import { ActivityService } from "@/service/sfr/activity_service.js";
import { MetierService } from "@/service/sfr/metier_service.js";

import * as exceptions from "@/service/exception_to_message.js";

import { defines as routes } from "@/router/defines.js";

import { RolesApplicationEnum } from "@/service/roles/roles_application.js";

export default {
  name: "TableEstablishments",
  components: { TableViewComponent },
  mixins: [SnackBarMixin],
  data() {
    return {
      establishmentService: null,

      /**en cours de chargement */
      loading: false,

      /**les établissements */
      establishments: [],

      /** les services. */
      serviceEstablishments: null,
      serviceActivities: null,
      serviceMetiers: null,

    };
  },
  methods: {
    onAddEstablishment() {
      this.$router.push(routes.etablishments.add.path);
    },
    async load() {
      try {
        this.loading = true;

        this.establishments = [];

        let establishments = await this.serviceEstablishments.getEstablishments(
          this.$api
        );

        let establishmentFillrates = await this.serviceEstablishments.getEstablishmentsFillrates();

        let activities = await this.serviceActivities.getActivities();

        let metiers = await this.serviceMetiers.getAll();

        establishments.forEach((e) => {
          let establishment = JSON.parse(JSON.stringify(e));

          establishment.activities = activities
            .filter((a) => e.activityIds.includes(a.id))
            .map((a) => a.name)
            .join(", ");

          // Récupère le nom du métier en fonction de son id dans la liste des métiers
          let metierName = "-";
          if (establishment.metierId) {
            metierName = metiers.find((m) => m.id == establishment.metierId)
              .name;
          }

          establishment.metier = metierName;

          if (e.address) {
            establishment.fullAddress = `${e.address.address} ${
              e.address.postalCode || ""
            } ${e.address.city || ""}`;
          }

          if (e.support) {
            establishment.supportDigitalName = establishments.find(
              (i) => i.id == e.support
            ).digitalName;
          }

          //action de consultation
          establishment.view = () => {
            this.$router.push(
              routes.etablishments.view.root + "/" + establishment.id
            );
          };

          //action de modification
          establishment.edit = () => {
            this.$router.push(
              routes.etablishments.edit.root + "/" + establishment.id
            );
          };

          //action de suppression
          establishment.delete = async () => {
            try {
              await this.serviceEstablishments.deleteEtablishment(
                establishment.id
              );
              this.load();
            } catch (error) {
              console.error(error);
              this.addErrorToSnackbar(
                "suppression de l'établissement : " +
                  (exceptions.toMessage(error) || "problème technique")
              );
            }
          };

          let establishmentFillrate = establishmentFillrates.find((m) => m.id == establishment.id);
          if (!establishmentFillrate) {
            console.error ("Impossible de trouver le taux de remplissage de l'établissement:" + establishment.id);
            establishment.percent = "erreur";
          }
          else {
            establishment.percent = Math.round(establishmentFillrate.rate);
          }
          this.establishments.push(establishment);
        });
      } catch (error) {
        console.error(error);
        this.addErrorToSnackbar(
          "chargement des données: " +
            (exceptions.toMessage(error) || "problème technique")
        );
      } finally {
        this.loading = false;
      }
    },
    /**Construire les colonnes à afficher dans la vue table */
    buildColumns() {
      //   support: null,

      // },

      let columns = [
        {
          text: "Nom digital",
          align: "start",
          sortable: true,
          value: "digitalName",
          default: true,
        },
        {
          text: "Métier",
          align: "start",
          sortable: true,
          value: "metier",
          default: true,
        },
        {
          text: "Activités",
          align: "start",
          sortable: true,
          value: "activities",
          default: true,
        },
        {
          text: "Statut",
          align: "start",
          sortable: true,
          value: "status",
          default: true,
          width: "100px",
        },
        {
          text: "Nom commercial",
          align: "start",
          sortable: true,
          value: "commercialName",
          default: true,
        },
        {
          text: "% remplissage",
          value: "percent",
          sortable: true,
          align: "center",
          width: "150px",
          default: true,
        },
        {
          text: "Adresse",
          align: "start",
          sortable: false,
          value: "fullAddress",
          default: false,
        },
        {
          text: "Département",
          align: "start",
          sortable: true,
          value: "departement",
          default: false,
        },
        {
          text: "Support par défaut",
          align: "start",
          sortable: true,
          value: "supportDigitalName",
          default: false,
        },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
          align: "center",
          width: "80px",
          default: true,
        },
      ];

      return columns;
    },
  },
  computed: {
    /**Retourne la liste des rôles attendus pour l'édition */
    rolesForEdition() {
      return [RolesApplicationEnum.EditEstablishmentDirection];
    },
    /** Retourne la liste des rôles attendus pour l'ajout/suppression */
    rolesForAddDelete() {
      return [ RolesApplicationEnum.AddDeleteEstablishmentDirection, ];
    },
    /**Retourne la liste des rôles attendus pour la lecture */
    rolesForRead() {
      return [ RolesApplicationEnum.DetailEstablishmentDirection, ];
    },
  },
  mounted() {
    this.serviceEstablishments = new EstablishmentsService(this.$api);
    this.serviceActivities = new ActivityService(this.$api);
    this.serviceMetiers = new MetierService(this.$api.getMetierApi());

    this.load();
  },
};
</script>

<style>
</style>